.header {
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #e65634;

  @media screen and (max-width: 1290px) {
    height: 120px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 107px 0 197px;
    height: 100%;

    .header-links {
      display: flex;
      gap: 30px;

      .item-header {
        color: #ffffff;
        transition: 0.2s ease-in-out;

        &:hover {
          color: #ff6c4a;
        }
      }

      .item-header-not-allow {
        color: #4e4e4e;
        cursor: not-allowed;
      }

      @media screen and (max-width: 1550px) {
        display: none;
      }
    }

    @media screen and (max-width: 1699px) {
      padding: 0 65px 65px;
    }

    @media screen and (max-width: 1290px) {
      flex-direction: column;
      justify-content: space-evenly;
    }

    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .header-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 36px 0 36px;
    height: 100%;
    position: relative;

    .burger-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border: 1px solid #ff6c4a;
      cursor: pointer;
      z-index: 4;

      p {
        color: #ff6c4a;
        font-size: 30px;
        transform: rotate(-180deg);
        padding-bottom: 10px;
      }

      @media screen and (max-width: 520px) {
        width: 44px;
        height: 44px;
      }

      span {
        margin: 6px 0;
        display: block;
        width: 40px;
        height: 3px;
        background: #e65634;

        @media screen and (max-width: 520px) {
          width: 25px;
          margin: 3px 0;
          height: 2px;
        }
      }
    }

    .header-left_logo {
      width: 86px;
      height: 86px;
      margin-left: 115px;
      z-index: 4;

      @media screen and (max-width: 678px) {
        width: 66px;
        height: 66px;
        margin-left: 0;
      }
    }

    .connect-for-mobile {
      width: 223px;
      height: 64px;
      font-size: 24px;
      z-index: 4;

      @media screen and (max-width: 520px) {
        width: 115px;
        height: 42px;
        font-size: 15px;
        padding: 0;
      }
    }

    .burger-menu-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 532px;
      background: #111122;
      z-index: 3;
      box-shadow: 0 15px 50px black;

      &_wrapper {
        margin-top: 133px;
        height: 100%;

        &-btn {
          @media screen and (max-width: 678px) {
            width: 464px !important;
          }

          @media screen and (max-width: 500px) {
            width: 364px !important;
          }

          @media screen and (max-width: 390px) {
            width: 264px !important;
          }
        }

        .logout-burger {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-right: 12px;
          }
        }

        button {
          margin-bottom: 23px;
          margin-right: 0;
        }
      }
    }

    @media screen and (max-width: 2999px) {
      display: none;
    }

    @media screen and (max-width: 1699px) {
      padding: 0 65px 65px;
      display: none;
    }

    @media screen and (max-width: 1290px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 960px) {
      display: flex;
    }

    @media screen and (max-width: 520px) {
      padding: 0 20px 65px;
    }
  }

  .header-left {
    display: flex;
    align-items: center;

    &_logo {
      width: 65px;
      height: 65px;
      position: relative;

      img {
        width: 100%;
      }

      span {
        position: absolute;
        top: 20px;
        left: -12px;
        color: #ffffff;
        background-color: #3e1c29;
        font-weight: bold;
      }
    }

    &_search {
      margin-left: 58px;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 1290px) {
      }

      input {
        margin-right: 45px;
        background: #11112280 0 0 no-repeat padding-box;
        width: 284px;
        height: 27px;
        padding: 3px 20px;
        border: 0;
        font-size: 14px;
        color: #ffffff;
      }

      .orange-border-button {
        margin: 0;
      }

      a:first-child {
        margin-right: 10px;
      }
    }

    &_cart {
      width: 28px;
      height: 26px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-right_logIn {
      display: flex;
      align-items: center;
      gap: 24px;

      .user-block {
        min-width: 130px;
        height: 33px;
        border-radius: 15px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        .avatar {
          width: 22px;
          height: 22px;
          background: #e65634;
          border-radius: 50%;
          margin-right: 7px;
        }
      }

      .token-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        min-width: 260px;
        height: 32px;
        background: #2b282c;
        border-radius: 15px;
        padding: 0 13px 0 7px;
        cursor: pointer;
        transition: 0.2s ease-in;

        &:hover {
          background: #ffffff;
          color: #000000;
          padding: 0 0px 0 20px;

          .alch-token,
          .wax-token {
            justify-content: center;
          }

          .token-icon {
            display: none;
          }
        }

        .alch-token,
        .wax-token {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
        }

        .token-icon {
          margin-right: 5px;
          width: 22px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .token-amount {
          font-size: 14px;
        }
      }

      .burger {
        width: 14px;
        height: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 20px;
        padding: 5px;

        &:hover {
          background: #ffffff;
        }

        &.open {
          background: #ffffff;
        }

        .bar {
          width: 100%;
          height: 2px;
          background-color: #8e8e8e;

          &.open {
            background-color: #000000;
          }
        }
      }

      .burger-menu-desctop-content {
        display: none;
        width: 134px;
        min-height: 220px;
        background: #2b282c 0 0 no-repeat padding-box;
        border-radius: 5px;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1000;
        padding: 26px;

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 26px;
          padding: 0;
          text-align: left;

          a {
            color: #ffffff;
          }

          li {
            font-size: 14px;
            transition: 0.2s ease-in-out;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              color: #e65634;
            }

            p {
              margin-left: 9px;
            }
          }
        }
      }

      .burger-menu-desctop-content.open {
        display: block;
      }
    }

    &_cart {
      margin-left: 17px;
      cursor: pointer;
    }
  }
}

.header-left_search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 324px;
  max-height: 200px;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: 999;
  padding: 0;
  margin: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.header-left_search-results li {
  list-style: none;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.header-left_search-results li:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.user-name {
  color: #d8d8d8;
  z-index: 4;
  position: relative;
  font-size: 14px;
}

.user-block {
  position: relative;
  min-width: 130px;
  height: 33px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  z-index: 5;

  .avatar {
    width: 22px;
    height: 22px;
    background: #e65634;
    border-radius: 50%;
    margin-right: 7px;
  }
}
